import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdaptiveTemplateRefDirective } from './adaptive-template-ref.directive';
import { AdaptiveTemplateServerComponent } from './adaptive-template-server.component';


@NgModule({
  declarations: [
    AdaptiveTemplateServerComponent,
    AdaptiveTemplateRefDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AdaptiveTemplateServerComponent,
    AdaptiveTemplateRefDirective
  ]
})
export class AdaptiveTemplateServerModule { }
