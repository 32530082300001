import { Component, ContentChildren, HostBinding, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdaptiveTemplateRefDirective } from './adaptive-template-ref.directive';


/**
 * An object literal, which describes the order of the templates at certain browser
 * viewports classfications (the width of the viewport to be precise)
 * xs: extra small
 * sm: small
 * md: medium
 * lg: large
 * xl: extra large
 */
export interface AdaptiveTemplateLayout {
  /**
   * order of the templates at certain browser viewport classfications (their breakpoint)
   */
  breakpoints?: {
    xs?: string[];
    sm?: string[];
    md?: string[];
    lg?: string[];
    xl?: string[];
  };
  /**
   * default order of adaptive template references, which will be used if a classification cannot be determin.
   * If a default order is not defined, the component will use the order of the templates in the view
   */
  default?: string[];
  /**
   * A rule that can be used to avoid repetition by allowing the component to select a smaller or larger
   * breakpoint's template order if there is no order for the current breakpoint.
   * - 'allowSmaller' is often used in "mobile-first" design paradigma
   * - 'allowLarger' is often used in "desktop-first" design paradigma
   * default value: 'onlyEqual'
   */
  breakpointRule?: 'allowSmaller' | 'allowLarger' | 'onlyEqual';
}


@Component({
  selector: 'app-adaptive-template-server',
  templateUrl: './adaptive-template-server.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AdaptiveTemplateServerComponent implements OnInit, OnDestroy {

  private _bpSubscription: Subscription;
  private _layoutData: AdaptiveTemplateLayout;
  private _currentBreakpoint: string;

  private _map = new Map<string, TemplateRef<any>>();
  private _viewOrder: string[] = [];

  @HostBinding('class')
  get _clazz(): string {
    return 'adaptive-template-server breakpoint-' + this._currentBreakpoint;
  }

  @ContentChildren(AdaptiveTemplateRefDirective, { descendants: false })
  set templates(value: QueryList<AdaptiveTemplateRefDirective>) {
    this._map.clear();
    this._viewOrder = [];
    value?.toArray()?.forEach(t => {
      this._map.set(t.adaptiveTemplateRef, t.host);
      this._viewOrder.push(t.adaptiveTemplateRef);
    });
    this.updateCurrentOrder();
  }

  @Input()
  set layout(value: AdaptiveTemplateLayout) {
    this._layoutData = value;
    this.updateCurrentOrder();
  }

  get layout(): AdaptiveTemplateLayout {
    return this._layoutData;
  }

  currentOrder$ = new BehaviorSubject<string[]>([]);

  constructor(private readonly breakpointService: BreakpointService) { }

  ngOnInit(): void {
    this._bpSubscription = this.breakpointService.breakpoint$.subscribe(bp => {
      this._currentBreakpoint = bp;
      this.updateCurrentOrder();
    });
  }

  ngOnDestroy() {
    this._bpSubscription?.unsubscribe();
  }

  getTemplate(ref: string) {
    return this._map.get(ref);
  }

  private updateCurrentOrder() {

    const bp = this._currentBreakpoint;
    let allowedBreakpoints = [bp];
    let order = [];

    if (this.layout) {
      if (this.layout.breakpointRule !== 'onlyEqual') {
        // an array of all breakpoints from the smallest to the largest
        let pool = this.breakpointService.breakpoints;
        const index = pool.indexOf(bp as BREAKPOINT);

        if (this.layout.breakpointRule === 'allowLarger') {
          allowedBreakpoints.push(...pool.slice(index + 1));
        }

        if (this.layout.breakpointRule === 'allowSmaller') {
          const tmp = pool.slice(0, index + 1).reverse();
          allowedBreakpoints.push(...tmp);
        }

      }

      const allowedOrder = allowedBreakpoints.map<string[]>(bp => this.layout.breakpoints?.[bp]).filter(order => !!order);

      order = allowedOrder?.[0] || this.layout?.default || [...this._viewOrder] || [];
    } else {
      order = [...this._viewOrder];
    }

    this.currentOrder$.next(order);
  }

}
