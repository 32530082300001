import { Directive, Host, Input, OnInit, Optional, TemplateRef } from '@angular/core';

/**
 * Use this directive to a reference to a <ng-template> within the content of an
 * AdaptiveTemplateServerComponent
 */
@Directive({
  selector: '[adaptiveTemplateRef]'
})
export class AdaptiveTemplateRefDirective {

  @Input()
  adaptiveTemplateRef: string;

  constructor(@Optional() @Host() public host: TemplateRef<any>) {}

}
